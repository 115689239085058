<template>
    <div>
        <button-top v-if="permissions.u" icon="EditIcon" tooltip="Editar especie" name="Editar" :date="form.created_at"
            @action="$router.push(`/gestion-especies/update/${form.id}`)"></button-top>

        <b-row>
            <b-col cols="3" class="font-weight-bold">Nombre común</b-col>
            <b-col cols="3">{{ form.name }}</b-col>
            <b-col cols="3" class="font-weight-bold">Nombre cientifico</b-col>
            <b-col cols="3">{{ form.scientific_name }}</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col cols="3" class="font-weight-bold">Familia</b-col>
            <b-col cols="3">{{ form.family_name }}</b-col>
            <b-col cols="3" class="font-weight-bold">Rango-altitud m.s.n.m</b-col>
            <b-col cols="3">{{ form.range_name }}</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col cols="3" class="font-weight-bold">Origen</b-col>
            <b-col cols="3">{{ form.origin_name }}</b-col>
            <b-col cols="3" class="font-weight-bold">Habito</b-col>
            <b-col cols="3">{{ form.habit_name }}</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col cols="3" class="font-weight-bold">Tipo de semilla</b-col>
            <b-col cols="3">{{ form.type_name }}</b-col>
            <b-col cols="3" class="font-weight-bold">Tipo de clima</b-col>
            <b-col cols="3">{{ form.climate_name }}</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col cols="3" class="font-weight-bold">Uso principal</b-col>
            <b-col cols="3">{{ form.main_use_name }}</b-col>
            <b-col cols="3" class="font-weight-bold">Uso secundario</b-col>
            <b-col cols="3">{{ form.secondary_use_name }}</b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="6" class="font-weight-bold">
                Descripción
            </b-col>

        </b-row>

        <b-row class="mt-1">
            <b-col cols="6">
                {{ form.description }}
            </b-col>
        </b-row>
    </div>
</template>
<script>

export default {
    data() {
        return {
            form: {
                id: '',
                name: '',
                scientific_name: '',
                family_name: '',
                range_name: '',
                origin_name: '',
                habit_name: '',
                type_name: '',
                climate_name: '',
                main_use_name: '',
                secondary_use_name: '',
                description: '',
            },
        }
    },
    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.form.id = this.$route.params.id;
            await this.getPermission();
            await this.get();
        },

        async get() {
            try {
                this.loading();
                this.form = (await this.$http.get(`production/specie/show/${this.form.id}`)).data.data;
                this.setHeaderTitle(`Id. ${this.form.id} / ${this.form.name}`);
            } catch (err) {
                if (err.response.status === 404) {
                    this.$router.push({ name: 'gestion-especies' })
                }
                this.notify("Error", err.response.data.message, 'danger')
            } finally {
                this.not_loading();
            }
        },


    },


}
</script>